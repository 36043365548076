import React, { Component } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

export default class Page extends Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO title={data.wordpressPage.title} />
        <ContentBlock>
          <h1>{data.wordpressPage.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.content,
            }}
          />
        </ContentBlock>
      </Layout>
    )
  }
}

export const query = graphql`
  query PageQuery($slug: String!) {
    wordpressPage(slug: { eq: $slug }) {
      title
      slug
      content
    }
  }
`
